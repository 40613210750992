<!-- 新增票据充值表单 -->
<template>
	<div :class="themeClass">
		<div class="margin-t-20">
			<div class="font-size18 font-color-333 font-weight700 margin-t-15">
				票据充值
			</div>
			<div class="margin-t-6 flex-column-align-center recharge-wallet-box border-F2F2F2">
				<div class="font-size16 flex-row-center-center">
					<span class="iconfont icon-biaodan color-theme font-size25"></span>
					新增票据信息（必填）
				</div>
				<!-- <div class="font-size14 font-color-333">选择充值金额</div>
				<div class="margin-t-10 flex-row-align-center">
					<div v-for="(item,index) in fixedAmount" :key="index" :class="item.checked?'border-color-theme background-color-theme':'font-color-666 border-E4E4E4'"
					 class=" flex-row-center-center amount-button pointer font-size16 margin-r-25" @mousedown="amountButtonMousedown(item)"
					 @mouseup="amountButtonMouseup(item)" @mouseout="amountButtonMouseout(item)">{{item.amountText}}</div>
				</div> -->
				<div class="form-box margin-t-10">
					<el-form ref="form" :rules="rules" :model="form" label-width="107px" v-loading="formLoading">
						<el-form-item label="店铺名">
							<div class="shopname-box hide-text">{{form.shopName}}</div>
							<!-- <el-input v-model="form.shopName" placeholder=" "></el-input> -->
						</el-form-item>

						<el-form-item label="票据类型" prop="fNoteTypeID">
							<el-select v-model="form.fNoteTypeID" placeholder="请选择票据类型" class="width-fill">
								<el-option v-for="(item,index) in billtypeList" :key="index" :label="item.fNoteType" :value="item.fNoteTypeID"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="票据号" prop="oddNumber">
							<el-input v-model="form.oddNumber" placeholder="请输入票据号" maxlength="30"></el-input>
						</el-form-item>
						<el-form-item label="票据金额" prop="amount">
							<el-input v-model="form.amount" @input="setOnlyMoney" @blur="getCapitalization" :maxlength="moneyMaxLeng" class="prefix-input"
							 placeholder="请输入票据金额">
								<span slot="prefix" class="font-color-FF0000">￥</span>
							</el-input>
						</el-form-item>
						<el-form-item label="票据金额大写">
							<div class="shopname-box hide-text">{{form.amountCapitalization}}</div>
						</el-form-item>
						<el-form-item label="出票日期" prop="issueDate">
							<el-date-picker type="date" placeholder="选择出票日期" v-model="form.issueDate" style="width: 100%;"></el-date-picker>
						</el-form-item>
						<el-form-item label="汇票到期日" prop="expireDate">
							<el-date-picker type="date" placeholder="选择汇票到期日" v-model="form.expireDate" style="width: 100%;"></el-date-picker>
						</el-form-item>
						<el-form-item label="出票人全称" prop="drawerName">
							<el-input v-model="form.drawerName" placeholder="请输入出票人全称"></el-input>
						</el-form-item>
						<el-form-item class="position-relative" label="出票人账号" prop="fDrawerAccountCode">
							<el-input v-model="form.fDrawerAccountCode" placeholder="请输入出票人账号">
							</el-input>
						</el-form-item>
						<el-form-item label="交票人全称" prop="deliveryMan">
							<el-input v-model="form.deliveryMan" placeholder="请输入交票人全称"></el-input>
						</el-form-item>
						<el-form-item label="承兑人全称" prop="acceptor">
							<el-input v-model="form.acceptor" placeholder="请输入承兑人全称"></el-input>
						</el-form-item>
						<el-form-item class="position-relative" label="承兑人账号" prop="fPayerAccountCode">
							<el-input v-model="form.fPayerAccountCode" placeholder="请输入承兑人账号">
							</el-input>
						</el-form-item>
						<el-form-item class="position-relative" label="承兑人开户行" prop="fPayerBankName">
							<el-input v-model="form.fPayerBankName" placeholder="请输入承兑人开户行">
							</el-input>
						</el-form-item>
						<el-form-item label="备注">
							<el-input v-model="form.fRemark" placeholder="备注"></el-input>
						</el-form-item>
						<el-form-item label="是否一手票">
							<el-switch v-model="form.isFirstTicket"></el-switch>
						</el-form-item>
						<el-form-item label="可转让">
							<el-switch v-model="form.transferable"></el-switch>
						</el-form-item>


						<!-- <el-form-item label="" id="setform" prop="radio">
							<div style="line-height: 20px !important;">
								<el-checkbox-group v-model="form.radio">
									<el-checkbox label="我已阅读并同意"></el-checkbox>
								</el-checkbox-group>
								<span v-for="(item,index) in pjAgreement" :key='index' style="display: inline-block;line-height: 20px;">
									<span style="cursor: pointer;" class="color-theme font-size12 lh21" @click="showAgreeDia(item.fAgreementHistID)">《{{item.fTitle}}》</span>
								</span>
							</div>
						</el-form-item> -->



						<!-- <el-form-item label="选择票据银行" prop="billBank">
							<el-select v-model="form.billBank" placeholder="请选择票据银行" class="width-fill">
								<el-option v-for="(item,index) in billBank" :key="'billBank'+index" :label="item.name" :value="item.id"></el-option>
							</el-select>
						</el-form-item> -->
					</el-form>
				</div>
				<div class="pointer recharge-ticket-button flex-row-center-center background-color-theme" @click="submitForm">确认充值</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		mapGetters
	} from "vuex";
	export default {
		data() {
			var endTime1 = (rule, value, callback) => {
				if (this.form.issueDate != '' && this.form.issueDate != null) {
					if (this.getDate(value) > this.getDate(new Date())) {
						callback(new Error('出票日期不能晚于当天'));
					} else {
						callback()
					}
				} else {
					callback()
				}
			};
			var endTime2 = (rule, value, callback) => {
				if (this.form.expireDate != '' && this.form.expireDate != null) {
					if (this.getDate(value) <= this.getDate(this.form.issueDate) ) {
						callback(new Error('汇票到期日需大于出票日'));
					} else {
						callback()
					}
				} else {
					callback()
				}
			}
			
			var oddNumberRule = (rule, value, callback) => {
				if (this.form.fNoteTypeID == 20 || this.form.fNoteTypeID == 22) {
					if (value.length == 30) {
						if (this.form.fNoteTypeID != '' && this.form.fNoteTypeID != null) {
							let firstStr = value.substring(0, 1);
							if (this.form.fNoteTypeID == 20 && firstStr != 1) {
								callback(new Error('票据号与票据类型不符'));
							} else if (this.form.fNoteTypeID == 22 && firstStr != 2) {
								callback(new Error('票据号与票据类型不符'));
							} else {
								callback();
							}
						} else {
							callback();
						}
					} else {
						callback(new Error('票据号与票据类型不符'));
					}
				} else {
					callback();
				}
			};
			var fNoteTypeRule = (rule, value, callback) => {
				if (this.form.oddNumber != '' && this.form.oddNumber != null) {
					let firstStr = this.form.oddNumber.substring(0, 1);
					if (value == 20 && firstStr != 1) {
						callback(new Error('票据号与票据类型不符'));
					} else if (value == 22 && firstStr != 2) {
						callback(new Error('票据号与票据类型不符'));
					} else {
						callback();
					}
				} else {
					callback();
				}
			};
			var amount = (rule, value, callback) => {
				if (value === '') {
					callback(new Error('金额不能为空'));
				} else {
					let amountRul = /(^(([1-9]([0-9]+)?)|(0{1}))(\.[0-9]{1,2})?$)/;
					if (amountRul.test(value)) {
						if (value == 0) {
							callback(new Error('金额不能为0'));
						} else if (value > 99999999.99) {
							callback(new Error('最大票据金额为99,999,999.99.99'));
						} else {
							callback();
						}
					} else {
						callback(new Error('金额为数字且最多有两位小数'));
					}
				}
			};
			return {
				pjAgreement: [],
				moneyMaxLeng: 11, //金额输入框最大长度
				formLoading: false, //表单加载
				// fixedAmount: [{
				// 		amount: 100,
				// 		amountText: "100",
				// 		checked: false,
				// 	},
				// 	{
				// 		amount: 500,
				// 		amountText: "500",
				// 		checked: false,
				// 	},
				// 	{
				// 		amount: 1000,
				// 		amountText: "1,000",
				// 		checked: false,
				// 	},
				// 	{
				// 		amount: 10000,
				// 		amountText: "10,000",
				// 		ischecked: false,
				// 	}
				// ],
				rules: {
					oddNumber: [{
						required: true,
						message: '票据号不能为空',
						trigger: 'blur'
					}, {
						validator: oddNumberRule,
						trigger: 'blur'
					}],
					amount: [{
						required: true,
						validator: amount,
						trigger: 'blur'
					}],
					fNoteTypeID: [{
						required: true,
						message: '票据类型不能为空',
						trigger: 'change'
					}],
					issueDate: [{
							required: true,
							message: '出票日期不能为空',
							trigger: 'blur'
						},
						{
							validator: endTime1,
							trigger: 'blur'
						}
					],
					expireDate: [{
						required: true,
						message: '汇票到期日不能为空',
						trigger: 'blur'
						},						
						{
							validator: endTime2,
							trigger: 'blur'
						}],
					drawerName: [{
						required: true,
						message: '出票人全称不能为空',
						trigger: 'blur'
					}],
					fDrawerAccountCode: [{
						required: true,
						message: '出票人账号不能为空',
						trigger: 'blur'
					}],
					deliveryMan: [{
						required: true,
						message: '交票人全称不能为空',
						trigger: 'blur'
					}],
					acceptor: [{
						required: true,
						message: '承兑人全称不能为空',
						trigger: 'blur'
					}],
					fPayerAccountCode: [{
						required: true,
						message: '承兑人账号不能为空',
						trigger: 'blur'
					}],
					fPayerBankName: [{
						required: true,
						message: '承兑人开户行不能为空',
						trigger: 'blur'
					}],
					
					radio: [{
						required: true,
						validator: (rule, value, callback) => {
							if (value != true) {
								callback(new Error('请阅读并同意票据协议'));
							} else {
								callback();
							}
						},
						trigger: 'change'
					}]
				}, //表单验证规则
				shopName: [],
				form: {
					shopName: '', //店铺名称
					fCompanyID: '', //店铺ID
					oddNumber: '', //票据号
					fCheckInBillCode: '', //登记单编码
					amount: '', //出票金额
					amountCapitalization: '', //出票金额大写
					fNoteType: '', //票据类型
					fNoteTypeID: '', //票据类型id
					issueDate: '', //出票日期
					expireDate: '', //汇票到期日
					drawerName: '', //出票人全称
					deliveryMan: '', //交票人全称
					acceptor: '', //承兑人全称
					isFirstTicket: false, //是否一手票
					transferable: true, //可转让
					// bankName: '',//票据银行
					fRemark: '',
					radio: false,
					fDrawerAccountCode:"",//出票人账号
					fPayerAccountCode:"",//承兑人账号
					fPayerBankName:"",//承兑人开户行
				},
				billtypeList: [], //票据类型数据
				billBank: [{
						name: "中国银行",
						id: 1
					},
					{
						name: "建设银行",
						id: 2
					},
					{
						name: "邮政银行",
						id: 3
					},
					{
						name: "工商银行",
						id: 4
					}
				]
			};
		},
		mounted() {
			let data = JSON.parse(this.$route.query.data);

			this.form.shopName = data.shopName;
			this.form.fCompanyID = data.shopID;
			this.getTicketInit();
			this.getBilltypeList();
			this.getReadAgreement();
		},
		computed: {
			...mapGetters(["getThemeName"]),
			themeClass() {
				return `theme-${this.getThemeName}`;
			},
		},
		methods: {
			//获取票据协议列表
			getReadAgreement() {
				this.ApiRequestPostNOMess('api/mall/ebbase/agreement-history/get-by-ebcn-list', {}).then(
					(result) => {
						if (result.obj) {
							this.pjAgreement = result.obj.items
						}
					},
					(rej) => {}
				);
			},
			//获取票据编码
			getTicketInit() {
				this.ApiRequestPost('api/mall/ebcn/note-check-in/init')
					.then(result => {
							// console.log(result);
							this.form.fCheckInBillCode = result.obj
						},
						error => {}
					)
			},
			//获取票据类型
			getBilltypeList() {
				this.formLoading = true;
				this.ApiRequestPost('api/mall/ebsale/note-type/get-note-type-list')
					.then(result => {
							this.formLoading = false;
							console.log(result);
							this.billtypeList = result.obj
						},
						error => {
							this.formLoading = false;
						}
					)
			},
			//输入金额限制
			setOnlyMoney() {
				this.$nextTick(() => {
					let val = this.form.amount.toString();
					val = val.replace(/[^\d.]/g, ""); //清除"数字"和"."以外的字符
					val = val.replace(/\.{2,}/g, "."); //只保留第一个. 清除多余的
					val = val.replace(/^0+\./g, '0.');
					val = val.match(/^0+[1-9]+/) ? val = val.replace(/^0+/g, '') : val
					val = (val.match(/^\d*(\.?\d{0,2})/g)[0]) || ''
					this.form.amount = val;
				});
			},
			//转换金额大写
			getCapitalization() {
				this.form.amountCapitalization = this.changeNumMoneyToChinese(this.form.amount)
			},
			//表单验证
			submitForm() {
				console.log(this.form);
				this.$refs.form.validate((valid, object) => {
					console.log(valid, object);
					if (valid) {
						this.confirmRecharge();
						// console.log('验证成功');
					} else {
						return false;
					}
				});
			},
			//确认充值
			confirmRecharge() {
				console.log(this.form.isFirstTicket);
				if (this.form.isFirstTicket == true) {
					this.form.isFirstTicket = 1;
				} else {
					this.form.isFirstTicket = 0;
				}
				if (this.form.transferable == true) {
					this.form.transferable = 1;
				} else {
					this.form.transferable = 0;
				}
				let param = {
					fBusiDate: this.getDate(this.form.issueDate), //登记日期
					fDrawDate: this.getDate(this.form.issueDate), //出票日期
					fEndDate: this.getDate(this.form.expireDate), //到期日期
					fIsFirst: this.form.isFirstTicket, //是否一手票据
					fIsTransfer: this.form.transferable, //是否可转让
					fMoney: this.form.amount, //票面金额
					fCompanyID: this.form.fCompanyID, //公司ID
					fNoteTypeID: this.form.fNoteTypeID, //票据类型ID
					fCheckInBillCode: this.form.fCheckInBillCode, //登记单编码
					fEndorsor: this.form.deliveryMan, //交票人名称
					fDraftCode: this.form.oddNumber, //票据号码
					fDrawer: this.form.drawerName, //出票人全称
					fPayerName: this.form.acceptor, //承兑人全称
					fRemark: this.form.fRemark, //备注
					fAppTypeID: this.fAppTypeID, //appID
					fDrawerAccountCode: this.form.fDrawerAccountCode,//出票人账号
					fPayerAccountCode:this.form.fPayerAccountCode,//承兑人账号
					fPayerBankName:this.form.fPayerBankName,//承兑人开户行
				}
				console.log(param);
				this.ApiRequestPost('api/mall/ebcn/note-check-in/create', param)
					.then(result => {
							console.log(JSON.stringify(result));
							this.$router.replace({
								path: '/businessme/billWallet',
							})
						},
						error => {
							console.log(JSON.stringify(error));
						}
					)
			},
			//金额鼠标按下事件
			amountButtonMousedown(e) {
				e.checked = true;
			},
			//金额鼠标松开事件
			amountButtonMouseup(e) {
				e.checked = false;
				this.form.amount = e.amount;
			},
			//金额鼠标移开
			amountButtonMouseout(e) {
				e.checked = false;
			},
		}
	};
</script>

<style lang="scss" scoped>
	.color-theme {
		@include themify($themes) {
			color: themed("themes_color");
		}
	}

	.background-color-theme {
		@include themify($themes) {
			background-color: themed("themes_color");
			color: themed("text_color_white") !important;
		}
	}

	.border-color-theme {
		@include themify($themes) {
			border: 1px solid themed("themes_color");
		}
	}

	.recharge-wallet-box {
		padding: 15px 0 0 15px;
		width: 900px;
		min-height: 1075px;
	}

	.amount-button {
		width: 103px;
		height: 41px;
		border-radius: 4px;
		user-select: none;
	}

	.amount-button:hover {
		@include themify($themes) {
			color: themed("themes_color");
			border: 1px solid themed("themes_color");
		}
	}

	.form-box {
		width: 420px;
	}


	/deep/.el-input__inner {
		color: #000000;
		font-size: 14px;
	}

	.el-form-item {
		margin-bottom: 17px;
	}

	.RMBicon {
		position: absolute;
		left: 1px;
		top: 0;
		z-index: 5;
	}

	.recharge-ticket-button {
		margin-top: 56px;
		width: 235px;
		height: 45px;
	}

	.shopname-box {
		margin-top: 5px;
		padding: 0 15px;
		height: 30px;
		line-height: 30px;
		border: 1px solid #f2f2f2;
		border-radius: 4px;
	}

	.prefix-input::v-deep {
		.el-input__inner {
			padding-left: 20px;
		}
	}
</style>
